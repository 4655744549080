.tableContainer {
  table {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
    border-radius: 0.25rem;
  }

  .stickyRight {
    position: sticky;
    right: 0;
    z-index: 1;
  }

  .tableHead {
    th {
      p {
        margin: 0;
        padding: 0.5rem 0.25rem 0.5rem 0;
      }
    }

    .stickyRight {
      z-index: 3;
    }
  }

  .tableHead:has(.stickyRight) {
    z-index: 5;
  }

  .tableBody {
    td {
      padding: 0.5rem 0.5rem 0.5rem 0;
      vertical-align: middle;
    }
  }

  &::-webkit-scrollbar {
    height: 6px;
  }
}
