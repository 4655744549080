.new-arrival-product{
	.new-arrivals-img-contnent{
		overflow:hidden;
		
		img{
			width:100%;
			@include transitionMedium;
		}
	}
	&:hover .new-arrivals-img-contnent img{
		transform: scale(1.5) translateY(12%);
		-moz-transform: scale(1.5) translateY(12%);
		-webkit-transform: scale(1.5) translateY(12%);
		-ms-transform: scale(1.5) translateY(12%);
		-o-transform: scale(1.5) translateY(12%);
	}
}


.product-grid-bx{
	.dz-media {
		margin-bottom: 35px;
		position:relative;
		height: 260px;
		img {
			border-radius: 12px;
			width: 100%;
			height: 100%;
			object-fit:cover;
			display: flex;
		}
	}
	.rating {
		background-color: #FF7F23;
		border-radius: 20px;
		color: #fff;
		align-items: center;
		position: absolute;
		padding: 5px 10px;
		box-sizing: border-box;
		left: 15px;
		text-align: center;
		bottom: -20px;
		display: block;
		right: 15px;
	}
	.item-title {
		line-height: 1.5;
		font-weight: 600;
		margin: 0 0 15px;
		font-size:18px;
		white-space: normal;
		a{
			color:#000;
		}
	}
	
	.price {
		color: $primary;
		font-weight: 700;
		font-size: 18px;
		del {
			font-size: 16px;
			font-weight: 500;
			color: #929292;
			margin-left: 12px;
		}
	}
}