.items-table{

	 tbody{
		
		tr{
			padding:0;
			border-radius: $radius;
			position: relative;
			box-shadow: 0 1px 0 0 #f8f8f8;
			@include transitionMedium;
			.dropdown{
				opacity:0;
			}
			&.active,
			&:hover{
				box-shadow: 0 4px 4px 0 rgba(0,0,0,0.25);
				z-index: 1;
				.dropdown{
					opacity:1;
				}	
			}
			th,
			td{
				border: 0;
				padding:18px 8px;
			}
		}
	}
	@include respond('phone') {
		width:500px;
	}	
}
.star-review{
	i{
		font-size:16px;
		&.text-gray{
			color:#d9d9d9;
		}
	}
	span{
		color:$black;
	}
}
// Form Head
.form-head{

	.breadcrumb{
		background:transparent;
		padding:0;
	}
	.search-area{
		max-width:400px;
		box-shadow: 0 15px 25px 0 rgba(0,0,0,0.06);
		border-radius: $radius;
		@include respond('laptop') {
			max-width:250px;
		}
		@include respond('phone') {
			max-width:250px;
		}
		
		.form-control{
			border: 0;
			height: 56px;
			padding: 10px 30px;
			font-size: 16px;
			@include respond('laptop') {
				height: 41px;
				padding: 10px 15px;
				font-size: 13px;
			}
		}
		.input-group-append .input-group-text{
			background:$white;
			padding: 0 30px;
			@include respond('laptop') {
				padding: 0 15px;
			}
			i {
				font-size: 24px;
				color:$body-color;
				@include respond('laptop') {
					font-size: 18px;
				}
			}
		}
	}
	.dropdown {
		.btn{
			
			i{
				transform: scale(1.3);
				-moz-transform: scale(1.3);
				-webkit-transform: scale(1.3);
				-ms-transform: scale(1.3);
				-o-transform: scale(1.3);
				display: inline-block;
			}
		}
	}
	.btn i{
		line-height: 1;
		transform: scale(1.3);
		display: inline-block;
		margin-right: 5px;
	}
	.btn-outline-primary{
		border-color:#eee;
		&:hover{
			border-color:$primary;
		}
	}
	& > *{
		@include respond('phone-land') {
			margin-right:5px !important;
			margin-bottom:10px;	
			display: inline-block;	
			vertical-align: baseline;
			margin-left:0 !important;
		}
	}
	@include respond('phone-land') {
		display:block !important;
	}
}
.rounded{
	border-radius:0.75rem !important;
}
.btn-link{
    font-weight: 500;
    font-size: 16px;
	text-decoration: none;
}
.deznav .copyright{
	font-size:12px;
	padding: 0 50px;
	color: #969ba0;
	margin-bottom: 40px;
	
	p{
		margin-bottom:10px;
	}
	
	@include respond('laptop') {
		padding:0 25px;
	}
}
.widget-buyer {
    .media {
        img {
            width: 30px;
        }
        .media-body {
            h5 {
                font-size: 14px;
                margin-bottom: 0px;
            }
            p {
                font-size: 13px;
            }
        }
    }
}

.recentOrderTable {
    table {
        margin-bottom: 0;
        overflow: hidden;
        thead {
            th {
                font-size: 12px;
            }
        }
        tbody {
            td {
                color: $dark;
                font-weight: 500;
                line-height: 40px;
                @at-root [data-theme-version="dark"] & {
                    color: $d-ctl;
                }
                img {
                    border-radius: 50px;
                }
                .custom-dropdown {
                    i {
                        padding: 0 10px;
                        cursor: pointer;
                    }
                }
            }
        }
    }
}
.activity_overview{
	.nav-tabs{
		border-bottom:1px solid rgba(255,255,255,0.2) !important;
	}
	.custom-tab-1 .nav-link{
		color:rgba(255,255,255,0.5);
		padding-left: 0;
		padding-right: 0;
		margin-right: 30px;
		
		&:hover,
		&.active{
			background:transparent;
			color:#fff;
			border-color: #fff;
		}
	}
}
.ps .ps__rail-x:hover,
.ps .ps__rail-y:hover,
.ps .ps__rail-x:focus,
.ps .ps__rail-y:focus,
.ps .ps__rail-x.ps--clicking,
.ps .ps__rail-y.ps--clicking {
    background-color: transparent!important;
    opacity: 0.9;
}

.chart-link {
    @include respond('phone') {
        display: flex;
        align-items: center;
    }
    @include respond('tab-land') {
        display: flex;
        align-items: center;
    }
    @include respond('desktop') {
        display: flex;
        align-items: center;
    }
    .week-link {
        display: inline-block;
        // @include respond('tab-land'){
        //     display: flex;
        // }
    }
    a {
        display: inline-block;
        font-size: 12px;
        font-weight: 500;
        margin-right: 10px;
        i {
            font-size: 13px;
            &.text-primary {
                color: $primary;
            }
            &.text-muted {
                color: #DAE2F3!important;
            }
        }
    }
    .form-control {
        max-width: 97px;
        display: inline-block;
    }
}

.card-tabs {
    .nav-tabs {
		border-bottom: 0px;
		background: $primary-light;
		padding: 5px;
		border-radius: 6px;
		flex-wrap: unset;
			
        .nav-link {
            border-radius:6px;
		    padding: 8px 12px;
			font-weight: 500;
			font-size:14px;
			border:0;
            &.active {
                background: $white;
                color: #000000;
            }
        }
    }
}

.revenue-chart .apexcharts-legend.apexcharts-align-left .apexcharts-legend-series{
	display:flex !important;
	margin-right: 30px !important;
	margin-bottom: 30px !important;
}
.revenue-chart-bar{
	margin-bottom:-30px;
	
	@include respond('phone') {
		margin-bottom: 20px;
		float: none;
		padding-left: 20px;
	}
}
@media only screen and (min-width: 1200px) and (max-width: 1600px) {
	.revenue-chart-bar{
		margin-bottom: 20px;
		float: none;
		padding-left: 20px;
	}
}
#activeUser {
    height: 215px!important;
}
#activeUser {
    height: 180px!important;
}
span#counter {
    font-size: 30px;
    font-weight: 700;
    color: $primary;
}
.social-graph-wrapper {
    text-align: center;
    padding: 20px;
    position: relative;
    color: $white;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
	
    &.widget-facebook {
        background-color: $facebook;
    }
    &.widget-twitter {
        background-color: $twitter;
    }
    &.widget-linkedin {
        background-color: $linkedin;
    }
    &.widget-googleplus {
        background-color: $google-plus;
    }
    .s-icon {
        font-size: 24px;
        position: relative;
        // top: 0.625rem;
        padding: 0 10px;
    }
}
.recent-stats i{
    font-size: 11px;
    position: relative;
    top: -1px;
}
@media only screen and (min-width: 1200px) and (max-width: 1600px) {
	#lineChart_3Kk{
		height: 140px !important;
	}
}
/* add menu sidebar */
.add-menu-sidebar{
	background: #2f4cdd; 
	background: -moz-linear-gradient(-45deg, #2f4cdd 0%, #d45bff 100%);
	background: -webkit-linear-gradient(-45deg, #2f4cdd 0%,#d45bff 100%);
	background: linear-gradient(135deg, #2f4cdd 0%,#d45bff 100%);
	padding: 20px 20px;
    border-radius: 20px;
    margin: 50px 50px 30px;
    text-align: center;
	@include respond('laptop') {
		 margin: 50px 25px 30px;
	}
	p{
		font-size:14px;
		line-height:1.4;
		color:#fff;
	}
	img{
		width: 75px;
		margin-top: -60px;
		margin-bottom: 15px;
	}
}
/* order manage */ 
.order-manage{
	background:$success-light;
	border-radius:12px;
}


.heart {
    width: 60px;
    height: 60px;
    display: inline-block;
    background: url(../images/like.png);
    cursor: pointer;
    margin: -25px -15px;
}
.heart-blast {
    background-position: -1680px 0;
    transition: background 1s steps(28);
}




//star rating
.rating-widget .rating-stars ul li i {
    font-size: 25px !important;
}

.logo-abbr{
	width:55px;
}
.logo-compact{
	width:130px;
}


.default-select{
	&.style-1{
		width: auto !important;
		height: auto!important;
		left: 0;
		.btn{
			padding:12px 20px;
			font-size:14px;
			border:0!important;
			color:$primary!important;
			background:rgba($primary,0.1)!important;
			&:after{
				color:$primary;
				margin-left: 8px;
				font-size: 20px;
			}
			&:hover{
				background:$primary!important;
				color:$white!important;
				&:after{
					color:$white!important;
				}
			}
		}
		@include respond ('laptop'){
			.btn{
				padding: 0.625rem 1rem;
				font-size: 0.813rem;
			}
		}
	}
}
.media{
	display: flex;
	align-items: flex-start;
}