.paginationContainer {
  gap: 1rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  .mainWrapper {
    gap: 0.75rem;
    display: flex;
    justify-content: center;
    align-items: center;
    width: max-content;

    .pagination {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 0;
      margin: 0;
      gap: 0.75rem;
      list-style-type: none;

      .active {
        border-radius: 0.625rem;
        background-color: var(--primary-green, #0077ff);
        color: white;
      }

      a {
        padding: 0.5rem;
        line-height: 1.75rem;
        width: 1.75rem;
        height: 1.75rem;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        border-radius: 0.625rem;
        border: 1px solid var(--black-shade-02, #64676e);
        cursor: pointer;

        &:hover {
          background-color: white;
          color: var(--primary-green, #0077ff);
          border: 1px solid var(--primary-green, #0077ff);
        }
      }
    }

    .disabled {
      opacity: 0.5;
      pointer-events: none;
      color: var(--black-shade-03, gray);
      border-radius: 0.625rem;

      path {
        stroke: var(--neutralColor3, #a8a8a8);
        fill: var(--black-shade-03, gray);
      }
    }

    .icon {
      cursor: pointer;
    }
  }

  .sizePickerLabel {
    font-size: 0.75rem;
    color: var(--neutralColor3, #a8a8a8);
    display: flex;
    gap: 1rem;
    align-items: center;

    span {
      color: var(--tertiary-black, #0d0f11);
      font-weight: bold;
      font-size: 0.875rem;
      white-space: nowrap;
    }

    :global {
      .paginationCustomSelect {
        & > div:nth-child(2) {
          & > div {
            border-radius: 0.625rem;

            & > div {
              min-width: 7.5rem;
              height: 1rem;
              overflow-y: hidden;

              * {
                margin: 0;
                padding: 0;
                line-height: 1;
                overflow-y: hidden;
              }
            }
          }
        }
      }
    }
  }
}

@media all and (min-width: 768px) {
  .paginationContainer {
    justify-content: space-between;
  }
}
