@import "../../abstracts/variable";

.form-check-label{
    margin-left: 5px;
}
.form-check-inline {
    .form-check-input {
        margin-right: 0.625rem;
    }
}
.custom-control-label{
	&:before,
	&:after{
		top:2px;
		width: 1.25rem;
		height: 1.25rem;
		border-color: #e7e7e7;
	}
}
.rtl .custom-control-label{
	&:before,
	&:after{
		right: -1.5rem !important;
		left: inherit;
	}
}
.custom-control {
    line-height: normal;
}
// CheckBox
.custom-control-input:focus ~ .custom-control-label::before{
	box-shadow:none !important;
}
.custom-control-label::before{
    background-color: transparent;
    border-color: $light;
	border-width: 2px;
	border-radius: 0.25rem !important;
		
	@at-root [data-theme-version="dark"] & {
		background-color: transparent;
		border-color: $d-border;
	}
}
.check-xs{
	.custom-control-label:before, 
	.custom-control-label:after{
		width: 18px;
		height: 18px;
	}
}
.check-lg{
	.custom-control-label:before, 
	.custom-control-label:after{
		width: 24px;
		height: 24px;
	}
}
.check-xl{
	.custom-control-label:before, 
	.custom-control-label:after{
		width: 28px;
		height: 28px;
	}
}
.checkbox{
	&-info{
		.custom-control-label::before{
			background-color: transparent;
			border-color: $info-light;
			@at-root [data-theme-version="dark"] & {
				background-color: $info-opacity;
				border-color: transparent;
			}
		}
		.custom-control-input:checked ~ .custom-control-label::before{
			background-color: $info;
			border-color: $info;
		}
	}
	&-danger {
		.custom-control-label::before{
			background-color:transparent;
			border-color: $danger-light;
			@at-root [data-theme-version="dark"] & {
				background-color: $danger-opacity;
				border-color: transparent;
			}
		}
		.custom-control-input:checked ~ .custom-control-label::before{
			background-color: $danger;
			border-color: $danger;
		}
	}
	&-success{
		.custom-control-label::before{
			background-color: transparent;
			border-color: $success-light;
			@at-root [data-theme-version="dark"] & {
				background-color: $success-opacity;
				border-color: transparent;
			}
		}
		.custom-control-input:checked ~ .custom-control-label::before{
			background-color: $success;
			border-color: $success;
		}
	}
	&-warning{
		.custom-control-label::before{
			background-color: transparent;
			border-color: $warning-light;
			@at-root [data-theme-version="dark"] & {
				background-color: $warning-opacity;
				border-color: transparent;
			}
		}
		.custom-control-input:checked ~ .custom-control-label::before{
			background-color: $warning;
			border-color: $warning;
		}
	}
	&-secondary{
		.custom-control-label::before{
			background-color: transparent;
			border-color: $secondary-light;
			@at-root [data-theme-version="dark"] & {
				background-color: $secondary-opacity;
				border-color: transparent;
			}
		}
		.custom-control-input:checked ~ .custom-control-label::before{
			background-color: $secondary;
			border-color: $secondary;
		}
	}
}
.toggle-switch{
	&.form-switch{
		.form-check-input{
			border: 0;
			background: #D8D8D8;
			width: 40px;
			border-radius: 20px !important;
			height: 13px;
			box-shadow:none;
			cursor:pointer;
			position:relative;
			&:after{
				width: 20px;
				background: #909090;
				height: 20px;
				content: "";
				position: absolute;
				border-radius: 24px;
				top: -3px;
				left: 0;
				box-shadow: 0 0 5px rgba(0, 0, 0, .3);
				-webkit-transition: all 0.2s;
				-ms-transition: all 0.2s;
				transition: all 0.2s;
			}
			&:checked{
				&:after{
					left:20px;
				}
			}
		}
	}
	&.text-start{
		padding-left: 0;
		padding-right: 50px;
		&.form-switch{
			.form-check-input{
				margin-left: 0;
				margin-right: -2.5em;
				float: right;
			}
		}
		.form-check-label{
			margin-left:0;
			margin-right:5px;
		}
	}
}