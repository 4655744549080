

.asColorPicker-dropdown {
    max-width: 26rem;
}

.asColorPicker-trigger {
    border  : 0 none;
    height  : 100%;
    position: absolute;
    right: 0;
    top     : 0;
    width   : 2.1875rem;
}
[direction="rtl"] .asColorPicker-trigger{
	left: 0;
	left: auto;
}
.asColorPicker-clear {
    display        : none;
    position       : absolute;
    right          : 1rem;
    text-decoration: none;
    top            : .5rem;
}



.daterangepicker {
    td.active {
        background-color: $primary;

        &:hover {
            background-color: $primary;
        }
    }

    button.applyBtn {
        background-color: $primary;
        border-color: $primary;
    }
}

.datepicker {

    &.datepicker-dropdown {
        background: $l-bg;
        border-radius: 1px;
        border: 1px solid $gallery;

        td.day, th.next, th.prev {
            height: 30px;
            width: 30px !important;
            padding: 0;
            text-align: center;
            font-weight: 300;
            border-radius: 50px;

            &:hover {
                @extend %gradient-9;
                box-shadow: 0px 0px 30px 5px rgba(243,30,122,0.3);
                color: $white;
            }
        }

        th.datepicker-switch, th.next, th.prev {
            font-weight: 300;
            color: $heading;
        }

        th.dow {
            font-weight: 300;
        }
    }
    table {

        

        tr td.selected, tr td.active.active {
            @extend %gradient-9;
            box-shadow: 0px 0px 30px 5px rgba(243,30,122,0.3);
            border: 0;
        }

        tr {
            td.today {
                @extend %gradient-9;
                box-shadow: 0px 0px 30px 5px rgba(243,30,122,0.3);
                color     : #ffffff;

                &:hover {
                    @extend %gradient-9;
                    box-shadow: 0px 0px 30px 5px rgba(243,30,122,0.3);
                    color     : #ffffff;
                }
            }

            td.today.disabled {
                @extend %gradient-9;
                box-shadow: 0px 0px 30px 5px rgba(243,30,122,0.3);
                color     : #ffffff;

                &:hover {
                    @extend %gradient-9;
                    box-shadow: 0px 0px 30px 5px rgba(243,30,122,0.3);
                    color     : #ffffff;
                }
            }
        }
    }
}


.picker{
	.picker__frame {
		min-width: 450px!important;
		max-width: 450px!important;
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%,-50%);
		height: auto;
		width: auto;
		bottom: unset;
		margin-bottom: unset;
		right: unset;
		
		.picker__box{
			padding: 20px;
			border: 0;
			box-shadow: 0 5px 10px rgba(0,0,0,0.1);
			
			.picker__header{
				margin-top: 0;
				
				.picker__year{
					color: $primary;
					font-style: inherit;
					font-size: 20px;
				}
			
				.picker__nav--prev,
				.picker__nav--next{
					width: 40px;
					height: 40px;
					padding: 0;
					line-height: 40px;
					border-radius: 2px;
					&:before{
						content: none!important
					}
					&:after{
						font-family: fontawesome;
						border: 0;
						margin: 0 auto;
						font-size: 22px;
					}
					&:hover{
						background-color: $primary!important;
						color: $primary!important;
					}
				}
				.picker__nav--prev{
					left: 0;
					&:after{
						content: "\f0d9";
					}
					&:hover{
						&:after{
							color: $white;
						}
					}
				}
				.picker__nav--next{
					right: 0;
					&:after{
						content: "\f0da";
					}
					&:hover{
						&:after{
							color: $white;
						}
					}
				}
			}
			
			.picker__table{
				.picker__weekday {
					padding: 10px 0;
					font-size: 16px;
				}
				.picker__day {
					width: 40px;
					height: 40px;
					border-radius: 50px;
					padding: 0!important;
					font-size: 16px;
					line-height: 40px;
					margin: auto;
					border: 0!important;
					
					&.picker__day--today{
						&:before {
							content: none !important;
						}
					}
					
					&.picker__day--highlighted {
						border: 0!important;
						padding: 0;
						background-color: $primary!important;
						color: $white!important;
					}
					
					&:hover{
						background-color: $primary!important;
						color: $white !important;
					}
				}
			}
			
			.picker__footer{
				.picker__button--today{
					background-color: $primary!important;
					color: $white !important;
					
					&:before{
						content: none!important
					}
				}
				
				.picker__button--clear, 
				.picker__button--close, 
				.picker__button--today{
					border: 0;
					border-radius: 2px;
					font-size: 16px;
					&:hover{
						background-color: $primary!important;
						color: $white !important;
						&:before{
							color: $white;
						}
					}
				}
			}
			
		}
				
		@include respond('phone') {
			min-width: 100%!important;
			max-width: 100%!important;
			
			.picker__box{
				padding: 15px;
				margin: 0 10px;
			}
		}
	}
	
}

.asColorPicker-wrap{
	.form-control{
		border-top-right-radius: 0;
		border-bottom-right-radius: 0;
	}
}